<template>
  <div class="info">
    <top-bar :title="'添加活动'" :left="true"></top-bar>

    <van-popup v-model="typeShow" position="bottom" style="max-height: 50%;">
      <div class="typeCheck">
        <span @click="typeShow = false">取消</span>
        <span>请选择</span>
        <span @click="typeConfirm">确定</span>
      </div>
      <van-checkbox-group class="typeCheckBox" @change="change" v-model="checkedValue">
        <van-cell-group v-for="(item, index) in typeList" :key="index">
          <van-cell  :title="item.label"  @click="toggle(index)">
            <template #icon>
              <van-checkbox :name="item" ref="checkboxes"/><van-icon v-if="item.children !== null" :name="childrenVisible ?'arrow-up':'arrow-down'" @click.stop="childrenVisible = !childrenVisible"/>
            </template>
          </van-cell>
          <van-checkbox-group v-if="childrenVisible" class="children" @change="childrenChange" v-model="childrenValue">
            <van-cell-group v-for="(items, i) in item.children" :key="i">
              <van-cell  :title="items.label"  @click="toggleChildren(i)">
                <template #icon>
                  <van-checkbox :name="items" ref="childrenBoxes"/>
                </template>
              </van-cell>
            </van-cell-group>
          </van-checkbox-group>
        </van-cell-group>
      </van-checkbox-group>
<!--      <van-picker title="标题" value-key="label" show-toolbar :columns="typeList" @confirm="typeConfirm" @cancel="typeShow = !typeShow" />-->
    </van-popup>
    <van-popup v-model="beginDateShow" position="bottom"  >
      <van-datetime-picker v-model="show.beginTime" @confirm="beginDateConfim" @cancel="beginDateShow = false"  type="datetime" title="选择年月日"/>
    </van-popup>
    <van-popup v-model="endDateShow" position="bottom"  >
      <van-datetime-picker v-model="show.endTime" @confirm="endDateConfim" @cancel="endDateShow = false" type="datetime" title="选择年月日"/>
    </van-popup>
    <van-popup v-model="surveyObjectShow" position="bottom"  >
      <van-picker title="面向对象" value-key="label" show-toolbar :columns="surveyObjectOptions" @confirm="surveyObjectConfirm" @cancel="surveyObjectShow = !surveyObjectShow" />
    </van-popup>
    <van-popup v-model="applyStartShow" position="bottom">
      <van-datetime-picker v-model="show.signBeginTime" type="datetime" title="请选择报名开始日期" confirm-button-text="确定" @confirm="applyStartConfirm"
        @cancel="applyStartShow= false" />
    </van-popup>
    <van-popup v-model="applyEndShow" position="bottom">
      <van-datetime-picker type="datetime" title="请选择报名结束日期" confirm-button-text="确定" :min-date="new Date(dataForm.signBeginTime)"
        @confirm="applyEndConfirm" @cancel="applyEndShow= false" />
    </van-popup>
    <van-popup v-model="needSignShow" position="bottom"  >
      <van-picker title="是否需要报名" value-key="label" show-toolbar :columns="options" @confirm="needSignConfirm" @cancel="needSignShow = !needSignShow" />
    </van-popup>

    <van-cell-group>
      <van-cell>
        <template #title>
          <div class="blue-box"></div>
          <span class="custom-title">基本信息</span>
        </template>
      </van-cell>
      <van-field clearable clear-trigger="always" v-model="dataForm.title" label="活动名称" placeholder="请输入" input-align="right" :readonly="isTasks" required/>
      <van-field clearable clear-trigger="always" v-model="dataForm.address" label="活动地点" placeholder="请输入" input-align="right" required/>
      <van-cell title="开始时间" :value="dataForm.beginTime || '请选择'" @click="beginDateShow = !beginDateShow"
                :value-class="{'value-common':!dataForm.beginTime}" is-link required />
      <van-cell title="结束时间" :value="dataForm.endTime || '请选择'"   @click="endDateShow = !endDateShow"
                :value-class="{'value-common':!dataForm.endTime}" is-link required />
      <van-field clearable clear-trigger="always" v-model="dataForm.joinNum" label="参与人数" placeholder="请输入" input-align="right" required />
      <van-field clearable clear-trigger="always" v-model="dataForm.speaker" label="主讲人" placeholder="请输入" input-align="right" />
      <van-field clearable clear-trigger="always" v-model="dataForm.mobile" label="联系电话" placeholder="请输入" input-align="right"/>
      <van-cell title="活动类型" :value="typeStr || '请选择'"  @click="!isTasks?typeShow = !typeShow : ''"
                :value-class="{'value-common':!typeStr}" is-link required />
      <van-cell title="面向对象" is-link  :value="surveyObjectStr || '居民'"  @click="surveyObjectShow = !surveyObjectShow" required />
      <van-cell title="是否需要报名" is-link :value="needSign"  @click="needSignShow = !needSignShow" required/>
      <template v-if="dataForm.needSign">
        <van-cell title="报名时间" is-link :value="dataForm.signBeginTime ? dataForm.signBeginTime + '至' + dataForm.signEndTime : ''"
          @click="applyStartShow = !applyStartShow"/>
      </template>
      <van-field clearable clear-trigger="always" v-model="dataForm.content" rows="3" :border="false" class="left" type="textarea"
        input-align="right" maxlength="50" placeholder="请输入活动描述"/>
      <van-row>
        <van-col :span="24">
          <!-- <van-uploader v-model="dataForm.fileList"   @delete="uploaddel" :after-read="uploadRead"/> -->
          <div class="upload">
            <upload-file
              :file-list="dataForm.fileList"
              :del-btn-show="edit"
              :upload-btn-show="edit"
              @filesUpload="filesUpload"
              @delFile="delFile"
              :upload-path="uploadPath"
              :file-type="'image'"/>
          </div>
        </van-col>
      </van-row>
    </van-cell-group>

    <van-row class="btns" style="background: transparent">
      <van-col :span="24">
        <van-button type="info" size="large" round @click="submit">完成</van-button>
      </van-col>
    </van-row>
  </div>
</template>

<script>
import topBar from '@/components/topBar/topBar'
import {formatterDateTimeWithOutSec} from '@/utils/utils'
import {getVirtualDict} from "@/utils/common";
import uploadFile from '@/components/upload/uploadFile'

export default {
  components :{
    topBar,
    uploadFile
  },
  data () {
    return {
      firstValue: [],
      uploadPath: 'files/activity/temporary',
      edit: true,
      secondValue: [],
      beginDateShow : false,
      endDateShow : false,
      typeShow : false,
      surveyObjectShow: false,
      applyStartShow: false,
      applyEndShow: false,
      needSignShow: false,
      isTasks: false,
      childrenVisible: false,
      typeStr: '',
      surveyObjectStr: '居民',
      typeList: [],
      surveyObjectOptions: [{value: '1', label:'居民'}, {value: '0', label: '社工/村委'}],
      options: [{value: 1, label: '是'}, {value: 0, label: '否'}],
      checkedValue: [],
      childrenValue: [],
      needSign: '否',
      dataForm: {
        id: 0,//活动id
        title: '',  //活动名称
        address: '', //活动地址
        beginTime: '', //开始时间
        endTime: '', //结束时间
        joinNum: '', //参与人数
        typeOptions: [],//活动类型选择器下拉列表
        type: '', //活动类型
        fileList:[],
        content: '', //活动描述
        speaker: '',  //主讲人
        mobile: '', //联系电话
        newFileMessages: [],
        deleteFiles: [],//删除的文件Id
        surveyObject: '1',  //默认面向居民
        needSign: 0,
        signBeginTime: '',
        signEndTime: '',
        putaway: 0
      },
      show: {
        beginTime: '',
        endTime: '',
        signBeginTime: ''
      }
    }
  },
  methods : {
    filesUpload (files) {
      this.dataForm.newFileMessages = files
      this.dataForm.fileMessages = files
    },
    delFile (id) {
      this.dataForm.deleteFiles.push(id)
    },
    getTypeOptions () {
      this.$http({
        url: this.$http.adornUrl('/wxapp/sys/dict/listDictByCode'),
        method: 'post',
        params: this.$http.adornParams({
          code: 'activityType',
          orgId: this.$orgId,
          showDisabled: false
        })
      }).then(({data})=> {
        if (data.code == 0) {
          let dicts = data.dicts
          let index = dicts.findIndex(item => {
            if (item.label === '党建引领') {
              return true
            }
          })
          if (index >= 0) {
            dicts.splice(index, 1)
          }
          this.typeList = dicts
        }
      },err=> {this.$toast.error({message:'活动类型获取失败'})})
    },
    /**
     * 获取活动面向对象
     */
    getActivitySurveyObjectOptions () {
      getVirtualDict('activitySurveyObject', (dicts) => {
          this.surveyObjectOptions = dicts
      })
    },
    submit () {
      this.dataForm.type = []
      // debugger
      if (this.childrenValue.length > 0) {
        this.childrenValue.map(item => {
          this.dataForm.type.push(item.value)
        })
      }
      if (this.checkedValue.length > 0) {
        this.checkedValue.map(item => {
          // if (!item.children || item.children.length < 1) {
            this.dataForm.type.push(item.value)
          // }
        })
      }
      this.dataForm.type = this.dataForm.type.join(',')
      if (this.dataForm.title == '') {return this.$toast.fail('失败:请输入活动名称');}
      if (this.dataForm.address === '') {return this.$toast.fail('失败:请输入活动地点');}
      if (this.dataForm.beginTime === '') {return this.$toast.fail('失败:请选择开始时间');}
      if (this.dataForm.endTime === '') {return this.$toast.fail('失败:请选择结束时间');}
      if (this.dataForm.joinNum === '') {return this.$toast.fail('失败:请输入参与人数');}
      // if (this.dataForm.speaker === '') {return this.$toast.fail('失败:请输入主讲人');}
      if (this.dataForm.type === '') {return this.$toast.fail('失败:请选择活动类型');}
      if (this.dataForm.needSign === '') {return this.$toast.fail('失败:请选择是否需要报名');}
      if (this.dataForm.surveyObject === '') {return this.$toast.fail('失败:请选择面向对象');}
      // if (this.dataForm.content === '') {return this.$toast.fail('失败:请输入活动描述');}

      this.$toast.loading({duration: 0,message: '提交中...',forbidClick: true,});
      this.$http({
        url: this.$http.adornUrl('/wxapp/activity/save'),
        method: 'post',
        data: this.$http.adornData({
          ...this.dataForm
        })
      }).then(({data})=> {
          this.$toast.clear()
        if (data.code == 0) {
          this.typeList = data.dicts
          localStorage.setItem('isRefresh',1)
          this.$toast.success({message:'新增成功'})
          this.$router.go(-1)
        }else{this.$toast.fail({message:data.msg})}
      },err=> {this.$toast.fail({message:'提交失败'})})
    },
    // 删除图片
    uploaddel (file, detail) {
      let i = detail.index
      this.dataForm.newFileMessages.splice(i, 1)
    },
    // 上传图片
    uploadRead (file) {
      this.$toast.loading({
        message: '上传中...',
        forbidClick: true,
        duration:0,
        overlay: true
      });

      if (!Array.isArray(file)) {
        file = [file]
      }
      file.forEach((item, index2) => {
        let formdata = new FormData();
        formdata.append("file", item.file);
        formdata.append("path", 'files/activity/temporary');
        this.$http({
          url: this.$http.adornUrl('/wxapp/file/upload'),
          method: 'post',
          data: formdata
        }).then(({data})=> {
          if (index2 === file.length - 1) {
            this.$toast.clear()
          }
            if (data.code == 0) {
              let uid = this.dataForm.newFileMessages.length
              data.fileMessage['uid'] = uid
              this.dataForm.newFileMessages.push(data.fileMessage)
              return true
            } else {
            this.$toast.fail(data.msg);
            }
          })
      })
    },
    typeConfirm () {
      this.typeShow = false
    },
    surveyObjectConfirm (value) {
      this.surveyObjectStr = value.label
      this.dataForm.surveyObject = value.value
      this.surveyObjectShow = false
    },
    needSignConfirm (value) {
      this.needSign = value.label
      this.dataForm.needSign = value.value
      this.needSignShow = false
    },
    change(val) {
        this.firstValue = val
        this.typeStr = val.map(function(item,index){
          return item.label}).concat(this.secondValue.map(item => {
          return item.label
        })).join(',')
        // this.dataForm.type = []
      //   val.map(e => {
      //     if (e.children !== null) {
      //       e.children.map(a => {
      //         this.childrenValue.push(a.value)
      //         this.childrenType.push(a.value)
      //       })
      //     this.dataForm.type.push(e.value)
      //   }
      // })
      this.typeShow = true
    },
    childrenChange (val){
      this.secondValue = val
      this.typeStr = val.map(function(item,index){
        return item.label}).concat(this.firstValue.map(item => {
        return item.label
      })).join(',')
    },
    toggle(index) {
      this.$refs.checkboxes[index].toggle();
    },
    toggleChildren(index) {
      this.$refs.childrenBoxes[index].toggle();
    },
    beginDateConfim (value) {
      let d = new Date(this.dataForm.endTime)
      if (this.dataForm.endTime != '' && d.getTime(d) < value.getTime(value)) {
        return this.$toast.fail('开始时间不能比结束时间大哦')
      }
      this.dataForm.beginTime = formatterDateTimeWithOutSec(value)
      this.show.beginTime = formatterDateTimeWithOutSec(value)
      this.beginDateShow = false
    },
    endDateConfim (value) {
      let d = new Date(this.dataForm.beginTime)
      if (this.dataForm.beginTime != '' && d.getTime(d) > value.getTime(value)) {
        return this.$toast.fail('结束时间不能比开始时间小哦')
      }
      this.dataForm.endTime = formatterDateTimeWithOutSec(value)
      this.show.endTime = formatterDateTimeWithOutSec(value)
      this.endDateShow = false
    },
    applyStartConfirm(value) {
      this.dataForm.signBeginTime = formatterDateTimeWithOutSec(value)
      this.show.signBeginTime = formatterDateTimeWithOutSec(value)
      this.applyStartShow = false
      this.applyEndShow = true
    },
    applyEndConfirm(value) {
      this.dataForm.signEndTime = formatterDateTimeWithOutSec(value)
      this.applyEndShow = false
    }
  },
  created() {
    if (this.$route.query.tasks) {
      this.dataForm.type = this.$route.query.dictId
      this.typeStr = this.$route.query.dictStr
      this.dataForm.title = this.$route.query.name
      this.isTasks = true
    }
    this.show.beginTime = new Date()
    this.show.endTime = new Date()
    this.show.signBeginTime = new Date()
    this.getTypeOptions()
    this.getActivitySurveyObjectOptions()
  }
}
</script>
<style lang="scss" scoped>
.upload {
  padding-left: 30px;
}
.typeCheck {
  display: flex;
  justify-content: space-between;
  height: 150px;
  line-height: 115px;
  padding: 0 40px;
  span {
    display: block;
    flex: 1;
    font-size: 30px;
  }
  span:nth-child(1) {
    font-size: 28px;
    color: #969799;
  }
  span:nth-child(3) {
    margin-right: -187px;
    font-size: 28px;
    color: #576b95;
  }
}
.typeCheckBox .van-cell__title {
  margin-left: 20px;
}
.typeCheckBox .van-icon {
  position: absolute;
  right: 40px;
  bottom: 45%;
}
.children {
  margin-left: 50px;
}
.children .van-cell__value {
  margin-left: 10px;
}
</style>
